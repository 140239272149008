import { z } from 'zod';

export const SectraIntegrationInputCredentials = z.object({
  v: z.string().optional().describe('The version of the integration'),
  id: z.string().optional().describe('The id of the integration'),
  sct: z.string().optional().describe('The sectret of the integration'),
});

export type SectraIntegrationInputCredentialsType = z.infer<typeof SectraIntegrationInputCredentials>;

export const SectraIntegrationOutputCredentials = SectraIntegrationInputCredentials.transform((data) => ({
  version: data.v,
  integrationId: data.id,
  integrationSecret: data.sct,
}));

export const IntegrationInputCredentials = z.object({
  secrta: SectraIntegrationInputCredentials,
}).partial();

export type IntegrationInputCredentialsType = z.infer<typeof IntegrationInputCredentials>;

export const IntegrationOutputCredentials = z.object({
  sectra: SectraIntegrationOutputCredentials,
}).partial();

export type IntegrationOutputCredentialsType = z.infer<typeof IntegrationOutputCredentials>;
