<div class="profile" nz-popover [nzPopoverContent]="popoverContent" nzPopoverPlacement="bottomRight">
  <div class="tenant-display-name">
    <div class="tenant-icon" [ngClass]="getColor(indexCurrentTenant())">
      <svg>
        <use [attr.href]="getImage(indexCurrentTenant())" />
      </svg>
    </div>
      <span>{{ tenantDisplayName() }}</span>
  </div>

  <nz-divider nzType="vertical" />

  <div class="profile__user-info">
    <span class="user-name">{{ user()?.firstName }} {{ user()?.lastName }}</span>
    <div class="dropdown-chevron">
      <nz-badge nzDot [nzShowDot]="couldUpgradeDataplane()" [nzOffset]="[5, -2]" >
        <dpqa-icon iconName="chevron-down-2" [iconSize]="0.8" />
      </nz-badge>
    </div>
  </div>
</div>

<ng-template #popoverContent>
  <div class="popover-content">
    <div class="user-content">
      <div class="tenant">
        <div class="tenant-icon" [ngClass]="getColor(indexCurrentTenant())">
          <svg>
            <use [attr.href]="getImage(indexCurrentTenant())" />
          </svg>
        </div>
      </div>
      <div class="user">
        <div class="user-info">
          <span class="tenant-name">{{ tenantDisplayName() }}</span>
          <span class="user-name">{{ user()?.firstName }} {{ user()?.lastName }}</span>
          <span class="user-email">{{ user()?.email }}</span>
        </div>
        <dpqa-button
          class="btn-logout"
          iconName="log-out"
          iconSize="1.6"
          [padding]="[1.2, 1, 1.2, 1]"
          [fontSize]="1.4"
          type="default"
          (click)="handleLogout()"
        >
          {{ "COMMON.SIGN_OUT" | translate }}
        </dpqa-button>
      </div>
    </div>

    @if (possibleTenants().length > 1) {
      <nz-divider nzType="horizontal" />

      <div class="tenants">
        <h2>{{ "NAVIGATION.OTHER_TENANTS" | translate }}</h2>
        <ul>
          @for (tenant of possibleTenants(); track tenant.id) {
            @if (tenant.id !== tenantId()) {
              <li>
                <a href="#" class="tenant" (click)="$event.preventDefault(); handleTenantChange(tenant.id)">
                  <div class="tenant-icon" [ngClass]="getColor($index)">
                    <svg>
                      <use [attr.href]="getImage($index)" />
                    </svg>
                  </div>
                  <span class="tenant-name">{{ tenant.displayName }}</span>
                </a>
              </li>
            }
          }
        </ul>
      </div>
    }

    <nz-divider nzType="horizontal" />

    <div class="versions">
      <h2>{{ "NAVIGATION.VERSIONS" | translate }}</h2>

      <div>
        <h3>{{ "COMMON.CLOUD" | translate }}</h3>
        <a href="#" (click)="$event.preventDefault(); copy('cloud')">{{ cloudVersion }}</a>
      </div>

      <div>
        <h3>{{ "COMMON.DATAPLANE" | translate }}</h3>
        <a href="#" (click)="$event.preventDefault(); copy('dataplane')">{{ dataplaneVersion() }}</a>
      </div>
    </div>

    @if (couldUpgradeDataplane()) {
      <nz-divider nzType="horizontal" />

      <div class="upgrade">
        <dpqa-button
          class="btn-upgrade"
          iconName="arrow-up"
          iconSize="1.6"
          [padding]="[1.2, 1, 1.2, 1]"
          [fontSize]="1.4"
          type="default"
          routerLink="/setup/upgrade/dataplane"
        >
          {{ "COMMON.UPGRADE_DATAPLANE" | translate }}
        </dpqa-button>
      </div>
    }
  </div>
</ng-template>
